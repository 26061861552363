import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../api';

import useGetAllCustomers from '../../hooks/useGetAllCustomers';
import FacilityDeletionModal from '../../components/Modal/FacilityDeletionModal';
import CustomerLookup from './CustomerLookup';
import Loader from '../../components/Loader';
import DeviceLogs from './DeviceLogs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-toastify/dist/ReactToastify.css';

const toastOptions = {
    position: 'top-center',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const CustomerDetails = () => {
    const [facilitiesList, setFacilitiesList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [address, setAddress] = useState([]);
    const [webAppLink, setWebAppLink] = useState('');
    const [webViewVersion, setWebViewVersion] = useState('v1');

    const history = useHistory();

    const { data: customers } = useGetAllCustomers();
    const customerId = window.location.pathname.split('/')[2];

    useEffect(() => {
        if (customerId !== undefined && customerId !== '' && customers.length !== 0) {
            let customer = customers.find(c => c.customer_id === decodeURIComponent(customerId));

            if (customer) {
                setLoading(false);
                loadCustomer(customer);
            } else {
                toast.error('Could not find customer with this number!', {
                    ...toastOptions,
                    position: 'top-right',
                    autoClose: 900,
                    onClose: () => history.push('/devicesdetail'),
                });
            }
        }
    }, [customers, customerId, webViewVersion]);

    const loadCustomer = async customer => {
        const addresses = new Set(customer.address_list);
        setAddress(Array.from(addresses));

        try {
            let webAppLink = (await api.customer_web_app_link(customer.customer_id, undefined, webViewVersion)).data.link;
            let customerFacilitiesRes = (await api.customer_facilities(customer.customer_id)).data;
            setFacilitiesList(customerFacilitiesRes);
            setWebAppLink(webAppLink);
            setLoading(false);
        } catch (error) {
            toast.error(`Could not load customer, please try again!`, toastOptions);
        }
    };

    const handleBackClick = () => {
        history.goBack();
    };

    return (
        <>
            <ToastContainer />
            {loading === true ? (
                <Loader />
            ) : (
                <div className="customer-details">
                    <div className="customer-details__header">
                        <button className="customer-details__header--back-btn" onClick={handleBackClick}>
                            &#60; Back
                        </button>

                        <div className="customer-details__header--search-customers">
                            <CustomerLookup searchPageActive={false} />
                        </div>
                    </div>
                    <div className="customer-details__main-container">
                        <div className="customer-details__first-section">
                            <div className="card-wrapper customer-details--header">
                                <div className="customer-details__card-header">Customer Information</div>
                                <div className="customer-details__customer-info">
                                    <p className="customer-details__customer">
                                        Customer:
                                        <span className="customer-details__customer-number"> {decodeURIComponent(customerId)}</span>
                                    </p>

                                    <div>
                                        <p className="customer-details__address">Address:</p>
                                        <ul>
                                            {address.map((a, i) => (
                                                <li key={i}>{a}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <DeviceLogs customerId={decodeURIComponent(customerId)} />
                        </div>

                        <div className="card-wrapper customer-details__iframe-wrap">
                            {facilitiesList?.length > 0 && (
                                <>
                                    <div className="customer-details__iframe-wrap--title-and-link">
                                        <div className="customer-details__card-header">App View</div>
                                        <div className="customer-details__iframe-wrap--links">
                                            <a href={webAppLink} target="_blank" rel="noreferrer">
                                                Customer App
                                                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                            </a>
                                            <Switch webViewVersion={webViewVersion} setWebViewVersion={setWebViewVersion}></Switch>
                                        </div>
                                    </div>
                                    <div className="customer-details__iframe-wrap--notch-container">
                                        <div className="customer-details__iframe-wrap--notch-container--notch"></div>
                                    </div>
                                    <iframe title="curstomer-app" className="customer-details__iframe-wrap--iframe-web-app" src={webAppLink}></iframe>
                                    <div className="customer-details__delete-facility">
                                        <FacilityDeletionModal facilities={facilitiesList}></FacilityDeletionModal>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomerDetails;

export const Switch = ({ webViewVersion, setWebViewVersion }) => {
    return (
        <div className="switch-container">
            <div className="switch">
                <input
                    type="radio"
                    name="balance"
                    value={false}
                    id="old"
                    className="switch__input switch__input--old"
                    checked={webViewVersion === 'v1'}
                    onChange={() => {
                        setWebViewVersion('v1');
                    }}
                />
                <label htmlFor="old" className="switch__label">
                    Old Web View
                </label>

                <input
                    type="radio"
                    name="balance"
                    value={true}
                    id="new"
                    className="switch__input switch__input--new"
                    checked={webViewVersion === 'v2'}
                    onChange={() => {
                        setWebViewVersion('v2');
                    }}
                />
                <label htmlFor="new" className="switch__label">
                    New Web View
                </label>

                <span className="switch__toggle"></span>
            </div>
        </div>
    );
};
