import React, { useEffect, useState } from 'react';
import api from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import InfoTooltip from '../../components/InfoTooltip/InfoTooltip';
import { Button } from '../../components/Common';

const toastOptions = {
    position: 'top-center',
    autoClose: 700,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
};

export const showComment = manufacturer => {
    switch (manufacturer.comment) {
        case 'manufacturers.comment.beta.new':
            return "This brand is new in our platform and we don't yet have enough data to determine the stability.";
        case 'manufacturers.comment.beta.unstable':
            return 'Stability is rated low. This means that it sometimes might affect the service. For example it might sometimes take longer before we detect that the EV is connected and optimization can begin.';
        case 'manufacturers.comment.alpha':
            return 'Coming soon...';
        case 'manufacturers.comment.development':
            return 'Under development...';
        case 'manufacturers.comment.development.deprecated':
            return 'Deprecated';
        case 'manufacturers.comment.beta.location_sharing':
            return 'Interventions often needed from the end user, for example enabling location sharing for the vehicle or a software update. Once this is done the smart charging will work.';
        case 'manufacturers.comment.beta.delayed_data':
            return 'Delayed data can sometimes cause problems with smart charging and visualization.';
        default:
            return '';
    }
};

const SelectBrand = () => {
    const [manufacturers, setManufacturers] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const manufacturerTypesTitles = {
        ev: 'Electric vehicles',
        cs: 'Charge stations',
        hvac: 'Heating',
        si: 'Solar inverters',
    };

    useEffect(async () => {
        let manufacturers = (await api.get_manufacturers()).data;
        setSelectedIds(manufacturers.filter(x => x.enabled === true).map(x => x.id));

        if (process.env.REACT_APP_ENV === 'production') {
            setManufacturers(manufacturers.filter(m => m.state === 'beta' || m.state === 'stable'));
        } else {
            setManufacturers(manufacturers);
        }
    }, []);

    const handleSelect = checked => {
        if (selectedIds.includes(checked)) {
            let newBrands = selectedIds.filter(x => x !== checked);
            setSelectedIds(newBrands);
        } else {
            setSelectedIds([...selectedIds, checked]);
        }
    };

    const handleSave = () => {
        api.set_manufacturers(selectedIds).then(
            _response => {
                toast.success(`Brand selection saved!`, toastOptions);
            },
            _error => {
                toast.error(`Error while saving brand selection`, toastOptions);
            }
        );
    };

    const brandLogo = image => {
        if (image.includes('own')) {
            return image.split('_')[1];
        }

        return image;
    };

    return (
        <div className="retailer-api">
            <ToastContainer />
            <h2 className="retailer-api__title">Select Manufacturers</h2>
            <div>
                <br />
                {Object.keys(manufacturerTypesTitles).map(type => {
                    const filteredManufacturers = manufacturers.filter(m => m.type === type);
                    return (
                        <div key={type}>
                            {filteredManufacturers.length > 0 && (
                                <div>
                                    <h4 className="retailer-api__subtitle">{manufacturerTypesTitles[type]}</h4>
                                    <div className="select-manufacturers__brands-wrapper">
                                        {filteredManufacturers.map(manufacturer => (
                                            <div className="select-manufacturers__brand-item" key={manufacturer.id}>
                                                <div className="select-manufacturers__brand-item--select-brand-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="brand"
                                                        id={`brand-${manufacturer.id}`}
                                                        checked={selectedIds.includes(manufacturer.id)}
                                                        onChange={() => handleSelect(manufacturer.id)}
                                                    />
                                                    <label htmlFor={`brand-${manufacturer.id}`}>{manufacturer.label.toUpperCase()}</label>
                                                    {manufacturer.state !== 'stable' && manufacturer.comment && manufacturer.comment !== '' && (
                                                        <InfoTooltip tooltip={showComment(manufacturer)} />
                                                    )}
                                                </div>
                                                <img
                                                    className="select-manufacturers__brand-item--brand-logo"
                                                    src={`https://app.emulate.network/assets/icons/logos/${brandLogo(manufacturer.id)}.png`}
                                                    alt="Logo"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className="select-manufacturers__save">
                <Button text="Save Selection" onClick={handleSave} />
            </div>
        </div>
    );
};

export default SelectBrand;
