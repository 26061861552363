import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import '../../../node_modules/font-awesome/css/font-awesome.min.css';

const Navbar = props => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div>
            <nav className="navbar">
                <div className="navbar__logo">
                    <NavLink exact to="/" className="navbar__logo--link">
                        <img src="/assets/emulate-logo.png" alt="main logo" />
                        <p>PORTAL</p>
                    </NavLink>
                </div>
                <ul className="navbar__nav">
                    <li className="navbar__nav-item dropdown no-arrow">
                        <span
                            onClick={() => setShowMenu(!showMenu)}
                            className="navbar__dropdown-toggle"
                            id="userDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="navbar__profile-image"></span>
                            <span className="navbar__profile-name">{props.traderName}</span>
                            <img src="/assets/icons/down-arrow.svg" className="navbar__nav-item--arrow-dropdown" alt="dropdown arrow" />
                        </span>
                        <div
                            className={'dropdown-menu dropdown-menu-right shadow animated--grow-in' + (showMenu ? ' show' : null)}
                            aria-labelledby="userDropdown"
                        >
                            <span className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                                <i className="fa fa-sign-out fa-sm fa-fw"></i>
                                <button className="navbar__logout-button" onClick={props.logout}>
                                    Logout
                                </button>
                            </span>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = disptach => {
    return {
        controlMainPowerDispatcher: () => disptach({ type: 'controlMainPower' }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
