import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import api from '../../api';
import { toast } from 'react-toastify';
import { Button, Input } from '../../components/Common';
import Loader from 'react-loader-spinner';
import YesNoModal from '../../components/Modal/YesNoModal';

const DEFAULT_CUSTOMER_ROLES = ['read', 'write'];
const DEFAULT_RETAILER_ROLES = ['customer_data_read', 'customer_data_write', 'reporting'];

const themeOptions = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const KeyManagement = () => {
    const [publicCustomerKeyName, setPublicCustomerKeyName] = useState('');
    const [publicCustomerKey, setPublicCustomerKey] = useState('');
    const [publicRetailerKeyName, setPublicRetailerKeyName] = useState('');
    const [publicRetailerKey, setPublicRetailerKey] = useState('');
    const [customerKeys, setCustomerKeys] = useState([]);
    const [retailerKeys, setRetailerKeys] = useState([]);
    const [customerKeyName, setCustomerKeyName] = useState('');
    const [retailerKeyName, setRetailerKeyName] = useState('');
    const [revokeModalOpen, setRevokeModalOpen] = useState(false);
    const [revokeId, setRevokeId] = useState('');
    const [revokeConsumerType, setRevokeConsumerType] = useState('');
    const [copyKeyModalOpen, setCopyKeyModalOpen] = useState(false);
    const [tempPrivateKey, setTempPrivateKey] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [generatingKey, setGeneratingKey] = useState(false);
    const [copiedValue, setCopiedValue] = useState(false);

    const retrieveKeys = async target => {
        try {
            setTableLoading(true);
            const keys = await api.api_keys(target);
            if (target === 'customer') {
                setCustomerKeys(keys.data);
            } else {
                setRetailerKeys(keys.data);
            }

            setTableLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        retrieveKeys('customer');
        retrieveKeys('retailer');
    }, []);

    const resetFieldValues = () => {
        setPublicCustomerKey('');
        setPublicCustomerKeyName('');
        setPublicCustomerKey('');
        setPublicRetailerKey('');
        setPublicRetailerKeyName('');
        setRetailerKeyName('');
    };

    const uploadPublicKeyIntegration = async (event, target) => {
        event.preventDefault();
        try {
            let apikeyPair = await api.upload_api_public_key(
                target,
                target === 'customer' ? publicCustomerKeyName : publicRetailerKeyName,
                target === 'customer' ? DEFAULT_CUSTOMER_ROLES : DEFAULT_RETAILER_ROLES,
                target === 'customer' ? publicCustomerKey : publicRetailerKey
            );
            if (apikeyPair.status === 200) {
                toast.success('Key Successfully Uploaded !', themeOptions);
            }

            resetFieldValues();
            retrieveKeys(target);
        } catch (error) {
            console.log(error);
        }
    };

    const generateKeyPairIntegration = async (event, target) => {
        event.preventDefault();
        let name;
        let roles;
        if (target === 'customer') {
            name = customerKeyName;
            roles = DEFAULT_CUSTOMER_ROLES;
        } else {
            name = retailerKeyName;
            roles = DEFAULT_RETAILER_ROLES;
        }
        try {
            setGeneratingKey(true);
            let apikeyPair = await api.generate_api_key_pair(target, name, roles);

            resetFieldValues();
            setCopyKeyModalOpen(true);
            setTempPrivateKey(apikeyPair.data.private_key);

            retrieveKeys(target);
            setGeneratingKey(false);
        } catch (error) {
            console.log(error);
        }
    };

    const uploadPublicKey = (target, cssClass) => {
        return (
            <div className={cssClass}>
                <p className="retailer-api__desc">
                    If you know how to generate a public/private key pair, do so and paste the public key below. This is the most secure option, since
                    the private part never needs to be sent to Emulate.
                </p>
                <form onSubmit={event => uploadPublicKeyIntegration(event, target)} className="key-management__form">
                    <div className="key-management__form--column">
                        <label>Key Name</label> <small>(for your reference)</small>
                        <Input
                            fullWidth
                            type="text"
                            onChange={event => {
                                if (target === 'customer') {
                                    setPublicCustomerKeyName(event.target.value);
                                } else {
                                    setPublicRetailerKeyName(event.target.value);
                                }
                            }}
                            value={target === 'customer' ? publicCustomerKeyName : publicRetailerKeyName}
                            required={true}
                        />
                    </div>
                    <div className="key-management__form--column">
                        <label>Public Key</label>
                        <textarea
                            className="default-input__textarea"
                            onChange={event => {
                                if (target === 'customer') {
                                    setPublicCustomerKey(event.target.value);
                                } else {
                                    setPublicRetailerKey(event.target.value);
                                }
                            }}
                            value={target === 'customer' ? publicCustomerKey : publicRetailerKey}
                        ></textarea>
                    </div>
                    <Button text="Upload" isSubmit />
                </form>
            </div>
        );
    };

    const generateKeyPair = (target, cssClass) => {
        return generatingKey === true ? (
            <div className="key-management__loader">
                <Loader type="Circles" height="80" width="60" color="#F57E02" ariaLabel="circles-loading" visible={true} />
            </div>
        ) : (
            <div className={cssClass}>
                <p className="reatiler-api__desc">
                    If you aren't familiar with generating keys, Emulate can produce a public/private key pair for you. You will be presented with the
                    private part only once, Emulate shall not retain it.
                </p>
                <form onSubmit={event => generateKeyPairIntegration(event, target)} className="key-management__form">
                    <div className="key-management__form--column">
                        <label>Key Name</label> <small>(for your reference)</small>
                        <br />
                        <Input
                            fullWidth
                            type="text"
                            value={target === 'customer' ? customerKeyName : retailerKeyName}
                            onChange={event => {
                                if (target === 'customer') {
                                    setCustomerKeyName(event.target.value);
                                } else {
                                    setRetailerKeyName(event.target.value);
                                }
                            }}
                            required={true}
                        />
                    </div>
                    <Button isSubmit text="Generate" />
                </form>
            </div>
        );
    };

    const existingKeyTable = (keys, apiConsumerType) => {
        return tableLoading === true ? (
            <div className="key-management__loader">
                <Loader type="Circles" height="80" width="0" color="#F57E02" ariaLabel="circles-loading" visible={true} />
            </div>
        ) : keys.length !== 0 ? (
            <table className="table key-management__keys-table">
                <thead>
                    <tr>
                        <th>Key Name</th>
                        <th>Date Added</th>
                        <th>Revoke</th>
                    </tr>
                </thead>
                <tbody>
                    {keys.map(key => (
                        <tr key={key.id}>
                            <td align="center">{key.name}</td>
                            <td align="center">
                                {(() => {
                                    let indexOfSlice = key.created_at.indexOf('T');
                                    return key.created_at.slice(0, indexOfSlice);
                                })()}
                            </td>
                            <td align="center">
                                <Button
                                    onClick={() => revokeModalHandler(key.id, apiConsumerType)}
                                    text="&#xf00d; Revoke"
                                    className="key-management__revoke-btn"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : (
            <p className="key-management__no-keys-message">No Keys Configured</p>
        );
    };

    const confirmRevokeHandler = () => {
        setRevokeModalOpen(false);
        deleteKeyHandler(revokeId, revokeConsumerType);
    };

    const cancelRevokeHandler = () => {
        setRevokeModalOpen(false);
    };

    const revokeModalHandler = (revokeId, revokeConsumerType) => {
        setRevokeModalOpen(true);
        setRevokeId(revokeId);
        setRevokeConsumerType(revokeConsumerType);
    };

    const deleteKeyHandler = async (revokeId, revokeConsumerType) => {
        const newKeys = revokeConsumerType === 'customer' ? [...customerKeys] : [...retailerKeys];

        const index =
            revokeConsumerType === 'customer'
                ? customerKeys.findIndex(key => key.id === revokeId)
                : retailerKeys.findIndex(key => key.id === revokeId);

        newKeys.splice(index, 1);
        revokeConsumerType === 'customer' ? setCustomerKeys(newKeys) : setRetailerKeys(newKeys);

        try {
            await api.revoke_api_key(revokeConsumerType, revokeId);
        } catch (error) {
            console.log(error);
        }
    };

    const confirmCopyKeyHandler = () => {
        navigator.clipboard.writeText(tempPrivateKey);
        setCopiedValue(true);
    };

    const cancelCopyKeyHandler = () => {
        setCopyKeyModalOpen(false);
        setCopiedValue(false);
    };

    return (
        <div className="retailer-api">
            <Popup open={revokeModalOpen} closeOnDocumentClick onClose={cancelRevokeHandler} className="modal">
                <div>
                    <div className="modal__header">Revoke Key</div>
                    <div className="modal__content">
                        Revoking this public key will prevent access to the API for any JWTs signed with the matching private key. Are you sure you
                        want to revoke it?
                    </div>

                    <div className="modal__actions">
                        <Button text="Yes" onClick={confirmRevokeHandler} />
                        <Button text="No" onClick={cancelRevokeHandler} className="modal__cancel" />
                    </div>
                </div>
            </Popup>

            <Popup open={copyKeyModalOpen} closeOnDocumentClick={false} onClose={cancelCopyKeyHandler} className="modal">
                <div>
                    <div className="modal__header">Copy New Key</div>
                    <div className="modal__content">
                        This private key has not been saved by Emulate. You should copy it now. It will not be displayed again.
                        <textarea className="key-management__copy-key" value={tempPrivateKey} readOnly rows="13"></textarea>
                    </div>

                    <div className="modal__actions key-management__copy-key-actions">
                        <Button text="Copy" onClick={confirmCopyKeyHandler} />
                        {copiedValue && <span className="key-management__tooltip">Successfully copied key!</span>}
                        <YesNoModal
                            title="Did you copy and save the key?"
                            message="The key will not be displayed again."
                            button="Close"
                            action={cancelCopyKeyHandler}
                            buttonClass="modal__cancel"
                        />
                    </div>
                </div>
            </Popup>
            <h2 className="retailer-api__title">Key Management</h2>
            <p className="retailer-api__desc key-management__description">
                The Emulate API is accessed by signing a<a href="https://jwt.io/"> JSON Web Token</a> using a private key. Emulate uses the public
                part of the key in order to verify the JWT.&nbsp;
                <strong>
                    The security of this scheme depends upon you keeping the private key secret. Never transfer it unencrypted or store it in version
                    control. Use a mechanism such as a secret store.
                </strong>
            </p>
            <h4 className="retailer-api__subtitle">Single Customer API</h4>
            <p className="retailer-api__desc">
                Single customer keys can only be used to access information about a single customer, with that customer being identified in the JWT
                payload. The JWT may be shared with a mobile app or frontend web application. We&nbsp;
                <strong>
                    strongly recommend including an <code>exp</code> (expiration time)&nbsp;
                </strong>
                in the JWT to limit its validity.
            </p>

            <div className="key-management__keys-wrapper">
                <p className="key-management__subtitle">Existing keys</p>
                <div>{existingKeyTable(customerKeys, 'customer', 'keys-table')}</div>
                <p className="key-management__subtitle">Upload Public Key</p>
                {uploadPublicKey('customer', 'public-key')}

                <p className="key-management__subtitle">Generate Key Pair</p>
                {generateKeyPair('customer', 'public-key')}
            </div>

            <h4 className="retailer-api__subtitle">Retailer API</h4>
            <p className="retailer-api__desc">
                Retailer keys can be used to access information about all customers of a retailer.&nbsp;
                <strong>
                    Any JWT produced using a retailer key must be kept secret, since possession of it grants access to information about all
                    customers!
                </strong>
            </p>

            <div className="key-management__keys-wrapper">
                <p className="key-management__subtitle">Existing keys</p>
                <div>{existingKeyTable(retailerKeys, 'retailer', 'keys-table')}</div>
                <p className="key-management__subtitle">Upload Public Key</p>
                {uploadPublicKey('retailer', 'public-key')}

                <p className="key-management__subtitle">Generate Key Pair</p>
                {generateKeyPair('retailer', 'public-key')}
            </div>
        </div>
    );
};

export default KeyManagement;
